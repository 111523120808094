import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const Videos = () => {
    const seo = {
        metaDesc: 'eWebinar Videos - Inventiv.org'
    }

    //
    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout activeMenu={'webinar'}>
            <SEO title="eWebinar Videos - Inventiv.org" canonical='/webinar-videos' seo={seo} />

            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>eWebinar Videos</h1>
                    </div>
                </div>
            </div>

            {/* <!-- Rsvp start --> */}
            <div class="rsvp content-area-2" id="rsvp">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <h3 class="sec-clr bold">Developing IP Strategy PatentPC</h3>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/g5oNpeN32v0?si=mf0HnM7B1Uvof2mg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                        </div>
                    </div>
                    <div className='row mt-5 py-5 bg-light-blue'>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <h3 class="sec-clr bold">PatentPC Complete Guide to Software Patenting</h3>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/5vU08Rz2ucY?si=AHzo_uF1b856TxPJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className='row mt-5 py-5'>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <h3 class="sec-clr bold">Inventor Wayne Turner Interview</h3>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/UdGPRTI4Mx4?si=FcEiauUKNVQVomof" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Rsvp end --> */}
        </Layout>
    )
};

export default Videos;